type On<T> = T extends string ? `on${Capitalize<T>}` : T

export function on<T extends {}>(emits: T) {
	const entries = Object.entries(emits).map<[string, FunctionConstructor]>(([e]) => [`on${capitalize(e)}`, Function])
	const onProps = Object.fromEntries(entries)

	return onProps as { [P in On<keyof T>]: FunctionConstructor }
}

function capitalize(s: string): string {
	return s[0].toUpperCase() + s.slice(1)
}
